import React from "react"
import { StaticQuery, graphql } from "gatsby"
import SeO from "../components/seo"

const Contact = () => (
    <StaticQuery
        query={graphql`
            query MyQuery {
                allContactJson {
                    edges {
                        node {
                            name
                            description
                        }
                    }
                }
            }
          
        `}
        render={data=>(
            <React.Fragment>
                <SeO title="Επικοινωνία" description="Επικοινωνία" />
                <ul style={{listStyleType: 'lower-greek'}}>
                    {
                        data.allContactJson.edges.map((item, index) => (
                            <li key={index} style={{paddingBottom: '1em'}}>
                                <React.Fragment>
                                    <strong>{item.node.name}</strong>
                                    <br />
                                    <div style={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        {item.node.description}
                                    </div>
                                </React.Fragment>
                            </li>
                        ))
                    }
                </ul>
            </React.Fragment>
        )}
  />
)

export default Contact